<template>
  <main id="main">
    <section id="exhibit" class="exhibit">
      <RequestChat/>
    </section>
  </main>
</template>

<script>
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import RequestChat from '../../components/Chat/RequestChatForm'
import useChat from '../../stores/useChat'
export default defineComponent({
  setup() {
    const chatStores = useChat()
    const loadingStore = useLoading()
    return {
      chatStores,
      loadingStore
    }
  },
  components : {
    RequestChat
  },
  mounted() {
    this.loadingStore.setLoading(true)
    const itemRequestNo = this.$route.params ? this.$route.params.id : null
    this.chatStores.memberCheck(itemRequestNo, 'request')
      .then(() => {
        this.chatStores.reset(itemRequestNo, 'request')
        .then(() => {
          this.$nextTick(() => {
            document.getElementById('chatInput')?.focus()
          })
          this.loadingStore.setLoading(false)
        })
        .catch(error => {
          this.loadingStore.setLoading(false)
          this.$router.go(-1)
        })
      })
      .catch(error => {
        this.loadingStore.setLoading(false)
        this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.SESSION_TOKEN)
        this.$cookies.remove(this.$define.LOCAL_STORE_LABEL.MEMBER_NO)
        this.$routerGo(this.$define.PAGE_NAMES.LOGIN_PAGE_NAME)
      })
  }
})
</script>

<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
