<template>
<div class="wrap1160 exhibit-wrap">
  <h1>{{ $t('CHAT_REQUEST_FROM_TITLE') }}</h1>
  <form id="entry-form">
    <Chat/>
  </form>
</div>
</template>

<script>
import useChat from '@/stores/useChat'
import useLoading from '@/stores/useLoading'
import {defineComponent} from '@vue/composition-api'
import Chat from './parts/Chat'

export default defineComponent({
  setup() {
    const loadingStore = useLoading()
    const chatStore = useChat()
    return {
      loadingStore,
      chatStore
    }
  },
  components : {
    Chat
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    }
  },
  data() {
    return {
    }
  },
  computed : {
    errors() {
      return this.chatStore.errors
    },
    chatData() {
      return this.chatStore.chatData
    },
  },
  methods : {
  },
  mounted() {
  },
  created() {
  },
  watch : {
    '$i18n.locale'() {
      // This.getConstants()
    },
    errors(value) {
      const keys = Object.keys(value)
      if (keys.length > 0) {
        const refkey = keys[0]
        if (value[keys[0]] && this.$refs[refkey]) {
          this.$refs[refkey].focus()
        }
      }
    }
  }
})
</script>
<style scoped lang="scss">
  .ipt-file-box {
    margin-bottom: 10px;
  }
  @media only screen and (min-width: 768px) {
    p.err-txt {
      position: absolute;
      top: 50%;
      left: 450px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
      max-width: 290px;
    }
    .textarea-content {
      background-color: white;
      width:430px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    p.err-txt {
        position: static;
        -webkit-transform: none;
        transform: none;
        max-width: 100%;
        margin-top: 5px;
    }
  }

  .with-int-no {
    width: 305px !important;
  }
  select.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  label.err {
    background-color: #fffafa !important;
    border: solid 1px #f00 !important;
  }
  .bold-text {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }
</style>
<style lang="scss" scoped>
  .est-form {
    margin-top: 10px;
    margin-bottom: 10px;
  }
</style>
<style lang="scss" scoped>
  .image-item {
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>
<style scoped lang="scss" src="@/assets/scss/style.scss"></style>
